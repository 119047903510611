import { ProductType } from "@/backend/BackendTypes";
import { goShipGreen } from "@/styles/Colors";
import { Button } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useContext } from "react";
import { GSAppContext } from "./context/GSAppContext";

export default function Product() {
  const router = useRouter();

  const {
    broker: { setProduct },
  } = useContext(GSAppContext);

  const productMap: ProductType[] = [
    {
      name: "Construction Materials",
      price: 600,
      image: "https://www.goship.com/wp-content/uploads/2023/11/AdobeStock_331598222-scaled.jpeg",
      length: 8,
      width: 10,
      height: 20,
      weight: 100,
    },
    {
      name: "Apparel",
      price: 550,
      image: "https://www.goship.com/wp-content/uploads/2023/11/AdobeStock_626331970-scaled.jpeg",
      length: 10,
      width: 10,
      height: 20,
      weight: 100,
    },
    {
      name: "Appliances",
      price: 450,
      image: "https://www.goship.com/wp-content/uploads/2023/11/AdobeStock_91779060-scaled.jpeg",
      length: 10,
      width: 10,
      height: 20,
      weight: 100,
    },
    {
      name: "Auto Parts",
      price: 3500,
      image: "https://www.goship.com/wp-content/uploads/2023/11/AdobeStock_625547650-scaled.jpeg",
      length: 10,
      width: 10,
      height: 20,
      weight: 100,
    },
    {
      name: "Furniture",
      price: 1000,
      image: "https://www.goship.com/wp-content/uploads/2023/11/AdobeStock_621274029-scaled.jpeg",
      length: 10,
      width: 10,
      height: 20,
      weight: 100,
    },
    {
      name: "Food and Beverage",
      price: 300,
      image: "https://www.goship.com/wp-content/uploads/2023/11/AdobeStock_626940739-scaled.jpeg",
      length: 10,
      width: 10,
      height: 20,
      weight: 100,
    },
  ];

  const onBuy = (product: ProductType) => {
    setProduct(product);
    router.push("/checkout");
  };
  return (
    <>
      <div className="flex flex-col justify-center">
        <div className="flex font-bold justify-center text-2xl mb-6">
          Select a Product from the List 
        </div>
      </div>
      <div className="m-2 md:grid md:grid-cols-3 gap-16">
        {productMap.map((p) => (
          <div id={p.name} className="text-center">
            <img
              src={p.image}
              alt=""
              className="border-2 border-grey border-solid"
            ></img>
            <div className="font-bold">${p.price}</div>
            <Button
              backgroundColor={goShipGreen}
              color={"white"}
              onClick={() => onBuy(p)}
            >
              Buy Now
            </Button>
          </div>
        ))}
      </div>
    </>
  );
}
